@import '@/assets/styles/templates.module.scss';

.main {
  @extend %main;
  display: flex;
  @extend %flex-center;

  z-index: 0;
  min-height: 70vh;
  height: 100%;

  padding: 126px 0 96px 0;
}
